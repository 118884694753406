.alert {
  background: #dfdf7a;
  border: 4px solid #000000;
  box-shadow: #000000 8px 8px 0 0;
  display: flex;
  font-family: "Bitter", serif;
  font-size: 1.25em;
  gap: 16px;
  margin: 0 auto;
  padding: 16px 16px 16px;
  transform: translateY(200%);
  transition: transform 240ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  max-width: 768px;

  &.is-active { transform: none; }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  button img {
    height: 48px;
    width: 48px;
  }
}
