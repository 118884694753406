.menu {
  align-items: center;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 16px;

  .menu-left, .menu-right {
    align-items: center;
    display: flex;
    gap: 16px;
    height: 100%;
  }

  .menu-logo {
    height: 64px;
    width: 64px;
  }

  .menu-right img {
    height: 48px;
    width: 48px;
  }

  .menu-right .menu-button span {
    justify-content: flex-end;
  }

  .menu-button {
    background: none;
    border: none;
    cursor: pointer;
    font-family: "SUSE", serif;
    font-size: 1.1em;
    font-weight: 700;
    height: 32px;
    letter-spacing: 3px;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-transform: uppercase;
    transition: letter-spacing 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
    width: 128px;

    span {
      align-items: flex-end;
      display: flex;
      height: 100%;
      justify-content: flex-start;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:hover {
      font-size: 1.2em;
      letter-spacing: 6px;
    }

    span:first-child {
      animation: 280ms 1 menuIn cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
    }

    span:last-child {
      animation: 280ms 1 menuOut cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
      transform: translateY(-32px);
    }

    &.is-active {
      span:first-child {
        animation: 280ms 1 menuOut cubic-bezier(0.68, -0.15, 0.265, 1.55) forwards;
      }

      span:last-child {
        animation: 280ms 1 menuIn cubic-bezier(0.68, -0.15, 0.265, 1.55) forwards;
      }
    }
  } //: .menu-button




} //: .menu


@keyframes menuIn {
  0% {
    transform: translateY(-32px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes menuOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(32px);
  }
}
