.projects-route {
  background: #dfbfbf;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  .projects-route-container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: calc(50vh - 256px) 0 64px;
    width: 100%;
  }

  .projects-route-banner {
    h2 {
      font-size: 2.5em;
      margin: 0;
      position: relative;
      text-transform: uppercase;

      &::first-letter {
        font-size: 1.5em;
      }
    }
  }

  .projects-route-spacer {
    background: #000000;
    border-radius: 8px;
    height: 50vh;
    width: 8px;
  }

  .projects-route-section {
    box-sizing: border-box;
    font-family: "Bitter", serif;
    padding: 64px;
    position: relative;
    width: 100%;
    max-width: 1024px;

    &::after {
      background: #000000;
      border-radius: 4px;
      bottom: 0;
      content: '';
      display: block;
      height: 4px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    h2 { font-size: 2em; }
    h3 { font-size: 1.5em; }

    h2, h3 {
      margin: 0;
      position: relative;
      text-transform: uppercase;

      &::first-letter {
        font-size: 1.5em;
      }

      &::after {
        background: #000000;
        bottom: -16px;
        content: '';
        display: block;
        height: 4px;
        position: absolute;
        width: 64px;
      }
    }

    p {
      font-size: 1.5em;
      line-height: 1.5em;
      letter-spacing: 1px;
    }

    button {
      background: #ffffff;
      border: 4px solid #000000;
      border-radius: 12px;
      box-shadow: #000000 8px 8px 0 0;
      color: #000000;
      cursor: pointer;
      display: block;
      font-weight: 700;
      padding: 16px 32px;
      text-transform: uppercase;
      transition: box-shadow 240ms ease, transform 240ms ease;

      &:hover {
        box-shadow: #000000 16px 16px 0 0;
        transform: translate(-4px, -4px);
      }
    }

    .projects-route-section-tags {
      display: flex;
      gap: 8px;
      list-style: none;
      padding: 8px 0 16px;

      li {
        background: #ffffff;
        border: 2px solid #000000;
        font-weight: 500;
        padding: 8px 16px;
        //&.tag-personal { background: #ffffff; }
        //&.tag-game { background: #ffffff; }
        &.tag-ios { background: #9fbfff; }
        &.tag-android { background: #aafeab; }
        //&.tag-mobile { background: #9ffbfb; }
        &.tag-web { background: #efabba; }
        &.tag-server { background: #bfbfbf; }
        //&.tag-work { background: #df9fff; }
      }

    }
  }
}
