.home-route {
  background: #bdcfc9;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  .home-route-container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: calc(50vh - 256px) 0 64px;
    width: 100%;
  }

  .home-route-welcome {
    align-items: center;
    display: flex;
    font: "Bitter", serif;
    height: 512px;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 768px;

    section {
      padding: 32px;
      width: 384px;
      z-index: 1;
    }

    h1 {
      font-size: 4em;
      line-height: 1em;
      margin: 0;
    }

    span {
      font-size: 2em;
      font-weight: 600;
    }

    img {
      border-radius: 50%;
      border: 8px solid #ffffff;
      flex: 0 0 256px;
      height: 256px;
      position: relative;
      width: 256px;
      z-index: 1;

    }

    &::before {
      background: #ffffff;
      border-bottom-right-radius: 64px;
      border-top-left-radius: 64px;
      //border-top-right-radius: 64px;
      box-shadow: #000000 16px 16px 0 0;
      content: '';
      display: block;
      height: 384px;
      right: 160px;
      position: absolute;
      width: 640px;
      z-index: 1
    }

    &::after {
      background: #000000;
      border-radius: 50%;
      content: '';
      display: block;
      height: 256px;
      position: absolute;
      right: 12px;
      top: 148px;
      width: 256px;
      z-index: 0;
    }
  }

  .home-route-spacer {
    background: #000000;
    border-radius: 8px;
    height: 50vh;
    width: 8px;
  }

  .home-route-section {
    box-sizing: border-box;
    font-family: "Bitter", serif;
    padding: 64px;
    position: relative;
    width: 100%;
    max-width: 1024px;

    &::after {
      background: #000000;
      border-radius: 4px;
      bottom: 0;
      content: '';
      display: block;
      height: 4px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    h2 { font-size: 2em; }
    h3 { font-size: 1.5em; }

    h2, h3 {
      margin: 0;
      position: relative;
      text-transform: uppercase;

      &::first-letter {
        font-size: 1.5em;
      }

      &::after {
        background: #000000;
        bottom: -16px;
        content: '';
        display: block;
        height: 4px;
        position: absolute;
        width: 64px;
      }
    }

    p {
      font-size: 1.5em;
      line-height: 1.5em;
      letter-spacing: 1px;
    }

    button {
      background: #ffffff;
      border: 4px solid #000000;
      border-radius: 12px;
      box-shadow: #000000 8px 8px 0 0;
      color: #000000;
      cursor: pointer;
      display: block;
      font-weight: 700;
      padding: 16px 32px;
      text-transform: uppercase;
      transition: box-shadow 240ms ease, transform 240ms ease;

      &:hover {
        box-shadow: #000000 16px 16px 0 0;
        transform: translate(-4px, -4px);
      }
    }
  }
}
