.about-route {
  background: #efdfcb;
  height: 100%;
  overflow-y: scroll;
  width: 100%;

  .about-route-container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: calc(50vh - 256px) 0 64px;
    width: 100%;
  }

  .about-route-banner {
    h2 {
      font-size: 2.5em;
      margin: 0;
      position: relative;
      text-transform: uppercase;

      &::first-letter {
        font-size: 1.5em;
      }
    }
  }

  .about-route-spacer {
    background: #000000;
    border-radius: 8px;
    height: 50vh;
    width: 8px;
  }

  .about-route-section {
    box-sizing: border-box;
    font-family: "Bitter", serif;
    padding: 64px;
    position: relative;
    width: 100%;
    max-width: 1024px;

    &::after {
      background: #000000;
      border-radius: 4px;
      bottom: 0;
      content: '';
      display: block;
      height: 4px;
      left: 0;
      position: absolute;
      width: 100%;
    }

    &.about-route-fill {

      background: #ffffff;
      border: 4px solid #000000;
      border-bottom-right-radius: 64px;
      border-top-left-radius: 64px;
      box-shadow: #000000 16px 16px 0 0;

      &::after { display: none; }
    }

    h2 { font-size: 2em; }
    h3 { font-size: 1.5em; }

    h2, h3 {
      margin: 0;
      position: relative;
      text-transform: uppercase;

      &::first-letter {
        font-size: 1.5em;
      }

      &::after {
        background: #000000;
        bottom: -16px;
        content: '';
        display: block;
        height: 4px;
        position: absolute;
        width: 64px;
      }
    }

    p, ul {
      font-size: 1.5em;
      line-height: 1.5em;
      letter-spacing: 1px;
    }

    ul {
      padding: 16px 32px;
    }

    ul li {
      list-style: none;
      position: relative;
      &::before {
        background-image: url('../../assets/icon-star.svg');
        content: '';
        display: block;
        height: 24px;
        left: -32px;
        position: absolute;
        top: 4px;
        width: 24px;
      }
    }

    button {
      background: #ffffff;
      border: 4px solid #000000;
      border-radius: 12px;
      box-shadow: #000000 8px 8px 0 0;
      color: #000000;
      cursor: pointer;
      display: block;
      font-weight: 700;
      padding: 16px 32px;
      text-transform: uppercase;
      transition: box-shadow 240ms ease, transform 240ms ease;

      &:hover {
        box-shadow: #000000 16px 16px 0 0;
        transform: translate(-4px, -4px);
      }
    }
  }
}
