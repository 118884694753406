.default-layout {
  box-sizing: border-box;
  position: fixed;
  overflow: hidden;
  bottom: 16px;
  left: 16px;
  right: 16px;
  top: 16px;

  .page {
    height: 100%;
    position: absolute;
    width: 100%;
  }
}

.default-layout-menu {
  position: absolute;
  width: 100%;
  z-index: 18;
}

.default-layout-alert {
  display: none;
  position: absolute;
  bottom: 32px;
  width: 100%;
  z-index: 17;
  &.is-active {
    display: block;
  }
}

.default-layout-component {
  animation: 280ms 1 animationClose cubic-bezier(0.39, 0.575, 0.565, 1);
  display: none;
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;

  &.is-active {
    animation: 280ms 1 animationOpen cubic-bezier(0.39, 0.575, 0.565, 1);
    display: block;
    pointer-events: all;
  }
}


@keyframes animationOpen {
  0% {
    display: none;
    transform: translateY(-100%);
  }
  100% {
    display: block;
  }
}

@keyframes animationClose {
  0% {
    display: block;
  }
  100% {
    display: none;
    transform: translateY(100%);
  }
}

