.contact {
  background: #a0bacf;
  height: 100%;
  width: 100%;

  .contact-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .contact-container form {
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: 100%;
    max-width: 512px;

    span.warning {
      background: #ff7f7f;
      border: 4px solid #000000;
      box-shadow: #000000 8px 8px 0 0;
      box-sizing: border-box;
      display: block;
      font-weight: 700;
      padding: 16px;
      text-transform: uppercase;
      width: 100%;
    }
  }

    button {
      background: #ff7755;
      border: 4px solid #000000;
      border-radius: 12px;
      box-shadow: #000000 8px 8px 0 0;
      color: #000000;
      cursor: pointer;
      display: block;
      font-size: 1.25em;
      font-weight: 700;
      padding: 16px 32px;
      text-transform: uppercase;
      transition: box-shadow 240ms ease, transform 240ms ease;

      &:hover {
        box-shadow: #000000 16px 16px 0 0;
        transform: translate(-4px, -4px);
      }
    }
}

.contact-input {
  height: 72px;
  position: relative;

  &.contact-input-textarea {
    height: 256px;
  }

  input, textarea {
    background: #ffffff;
    border: 4px solid #000000;
    //border: none;
    border-radius: 12px;
    box-sizing: border-box;
    box-shadow: #000000 8px 8px 0 0;
    font-size: 1.5em;
    outline: none;
    padding: 32px 16px 12px;
    height: 100%;
    position: absolute;
    transition: box-shadow 240ms ease, transform 240ms ease, background 320ms ease;
    width: 100%;

    &:hover {
      box-shadow: #000000 16px 16px 0 0;
      transform: translate(-4px, -4px);
    }
    &:focus {
      background: #ffffaa;
      box-shadow: #000000 16px 16px 0 0;
      transform: translate(-4px, -4px);
    }
  }

  textarea {
  }

  label {
    font-size: 0.9em;
    font-weight: 700;
    padding: 8px 16px;
    position: absolute;
  }
}
