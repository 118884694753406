.navigation {
  //background: #badac6;
  background: #c0c9cf;
  height: 100%;
  width: 100%;

  .navigation-container {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: 100%;
    justify-content: center;
    padding: 96px 16px 16px;
    width: 100%;
  }

  // SOCIAL ----------------------------------------------------------------------------------------

  .navigation-social {
    align-items: center;
    display: flex;
    gap: 16px;
    height: 48px;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;

    li {
      height: 48px;
      width: 48px;

      img {
        cursor: pointer;
        height: 100%;
        transition: transform 240ms ease;
        width: 100%;
      }

      &:hover {
        img {
          transform: scale(0.85);
        }
      }
    }

    &::before, &::after{
      background: #000000;
      border-radius: 12px;
      content: '';
      display: block;
      height: 8px;
      position: absolute;
      top: 20px;
      width: 72px;
    }

    &::before {
      left: -16px;
      transform: translateX(-100%);
    }
    &::after {
      right: -16px;
      transform: translateX(100%);
    }
  }

  // MAIN LIST -------------------------------------------------------------------------------------

  .navigation-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style: none;
    padding: 0;
    button {
      width: 384px;

      &.is-active {
        background: #99ff99;
      }
    }
  }

  //.navigation-list {
  //  box-sizing: border-box;
  //  display: grid;
  //  gap: 32px;
  //  grid-template-columns: repeat(1, auto);
  //  list-style: none;
  //  height: 100%;
  //  max-height: 768px;
  //  margin: 0;
  //  overflow-y: scroll;
  //  padding: 16px;
  //  width: 100%;
  //  max-width: 1024px;

  //  @media (min-width: 1024px) {
  //    grid-template-columns: repeat(2, auto);
  //  }
  //}


  //.navigation-item {
  //  border: 16px solid #ffffff;
  //  border-bottom-right-radius: 16px;
  //  border-top-left-radius: 16px;
  //  box-shadow: #000000 16px 16px 0 0;
  //  box-sizing: border-box;
  //  overflow: hidden;
  //  color: rgba(255,255,255,0.90);
  //  cursor: pointer;
  //  filter: grayscale(100%);
  //  height: 90%;
  //  margin: 5%;
  //  text-shadow: rgba(0,0,0,0.92) 0 2px;
  //  transition: all 240ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  //  width: 90%;

  //  & > a {
  //    align-items: center;
  //    background: radial-gradient(rgba(0,0,0,0.16), rgba(0,0,0,0.92));
  //    display: flex;
  //    flex-direction: column;
  //    gap: 8px;
  //    height: 100%;
  //    justify-content: center;
  //    width: 100%;

  //  }

  //  span {
  //    font-size: 3em;
  //    font-weight: 700;
  //    letter-spacing: 4px;
  //    text-transform: uppercase;
  //    &::first-letter {
  //      font-size: 1.35em;
  //    }
  //  }

  //  p {
  //    font-size: 1.5em;
  //    font-weight: 600;
  //    margin: 0;
  //  }

  //  &:hover {
  //    box-shadow: #000000 20px 20px 0 0;
  //    color: rgba(255,255,255,1.0);
  //    filter: none;
  //    @media (min-width: 1024px) {
  //      transform: translate(-8px,-8px) scale(1.05);
  //    }
  //  }

  //  &.navigation-item-home {
  //    background: url('../../assets/nav-home.png') no-repeat;
  //    background-position: center;
  //    background-size: cover;
  //  }
  //  &.navigation-item-projects { background: url('../../assets/nav-projects.jpg'); }
  //  &.navigation-item-about { background: url('../../assets/nav-about.jpg'); }
  //  &.navigation-item-blog { background: url('../../assets/nav-blog.png'); }

  //}
}

@keyframes navigationOpen {
  0% {
    //height: 25%;
  transform: translateY(-100%);
  display: none;
  }
  100% {
    //height: 100%;

  display: block;
  }
}

@keyframes navigationClose {
  0% {
    display: block;
    //height: 100%;
  }
  100% {
    display: none;
    transform: translateY(100%);
  }
}
