html, body { height: 100%; }
body {
  box-sizing: border-box;
  margin: 0;
  font-family: 'SUSE', 'Helvetica Neue', sans-serif;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}

.font-serif {
  font: 'Bitter', serif;
}

a {
  color: inherit;
  text-decoration: none;
}

button.theme-btn {
  background: #ffffff;
  border: 4px solid #000000;
  border-radius: 12px;
  box-shadow: #000000 8px 8px 0 0;
  color: #000000;
  cursor: pointer;
  display: block;
  font-size: 1.25em;
  font-weight: 700;
  padding: 16px 32px;
  text-transform: uppercase;
  transition: box-shadow 240ms ease, transform 240ms ease;

  &:hover {
    box-shadow: #000000 16px 16px 0 0;
    transform: translate(-4px, -4px);
  }
}

