.loading {
  background: #ffffff; //#7f398f;
  height: 100%;
  width: 100%;

  .loading-container {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
}

@keyframes loadingOpen {
  0% {
    transform: translateY(-100%);
    display: none;
  }
  100% {
    display: block;
  }
}

@keyframes loadingClose {
  0% {
    display: block;
  }
  100% {
    display: none;
    transform: translateY(100%);
  }
}

